import Header from './components/Header/Header';
import MyRoutes from './components/routers/routes';

import backgroundImage from '../src/images/3.jpeg';
import RandomBackground from "./components/common/RandomBackground";
import Footer from './components/Footer/Footer';
import './App.css';

function App() {
  const appStyle = {
      backgroundImage: `url(${RandomBackground()})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed' // added this line
  };
  return (
    <div style={appStyle}>
      <Header />
      <MyRoutes />
      <Footer />
    </div>
  );
}

export default App;
