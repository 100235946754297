import React, { useEffect, useState } from 'react';
import { coinContractAbi } from '../../contracts/coinContract';
import { coinByteCode } from '../../contracts/coinContract';
import { useNavigate, useParams, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useConnectWallet } from '@web3-onboard/react';
import axios from 'axios';
import { ethers } from 'ethers';
import Fee from './Fee';
import Reward from './Reward';
// import Other from './Other';
import { ToastContainer, toast } from 'react-toastify';
// import WithdrawAirdropNft from './WithdrawAirdropNft';
// import BoostNft from './BoostNft';
import { checkAdmin, developerAddr, superAdmin } from '../../constants';
// import Reward2 from './Reward2';
import bigInt from 'big-integer';
import Reward2 from './Reward2';


const Admin = () => {
  const location = useLocation();
  console.log("🚀 ~ Admin ~ location:", location)
  const params = useParams();
  const [formData, setFormData] = useState({});
  const [newContract, setNewContract] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const userData = useSelector((state) => state?.state);
  const [stakingFees, setStakingFees] = useState(false)
  const [stakeFee, setStakeFee] = useState(0);
  const [unStakingFee, setUnStakingFee] = useState(0);
  const [claimFee, setClaimFee] = useState(0);

  useEffect(() => {
    if (newContract) {
      let obj = {
        name: formData.name,
        description: formData.description,
        contract_address: newContract,
        creator_address: wallet?.accounts[0].address,
        reward_token_address: formData.rewardTokenAddress,
        staking_token_address: formData.stakingTokenAddress,
        stakeFee: stakeFee,
        unStakefee: unStakingFee,
        claimFee: claimFee,
      };

      const createCollectionn = async () => {
        let _createCollection = await axios
          .post(`${process.env.REACT_APP_API_URL}coinContract`, obj)
          .then((res, err) => {
            if (err) {
              toast.error('Something went wrong!');
              return err;
            }
            toast.success('Transaction successful!');
            // navigate('/');
          });
      }
      createCollectionn()
    }
  }, [newContract]);


  useEffect(() => {
    if (wallet) {
      if (
          wallet?.accounts[0]?.address.toLowerCase() ===
          '0xBFf06DEb7059B475a734E71D101b2D9fF3247cFF'.toLowerCase()
      ) {
        // console.log('🚀 ~ useEffect ~ admin 1 isnotadmin',wallet?.accounts[0]?.address.toLowerCase());
        if(params?.address){
          navigate(`/admin/${params?.address}`);

        }else{

          navigate('/admin');
        }

      } else {
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [wallet]);

  //uncomment when deployed
  // useEffect(() => {
  //   if (wallet) {
  //     if (
  //       wallet?.accounts[0]?.address.toLowerCase() ==
  //       '0xbba8732ee7c9e61bc05af01006785d0d6cd2471e' ||
  //       wallet?.accounts[0]?.address.toLowerCase() ==
  //       '0x6bb81ca8ec2f7ccbb08a5b1df8c32781ef3e1c2d'
  //     ) {
  //       if (params?.address) {
  //         navigate(`/admin/${params?.address}`);

  //       } else {

  //         navigate('/admin');
  //       }

  //     } else {
  //       navigate('/');
  //     }
  //   } else {
  //     navigate('/');
  //   }
  // }, [wallet]);

  const submitHandler = async (e) => {
    try {

      e.preventDefault();
      // debugger;

      const etherProvider = () => {
        try {
          return new ethers.providers.Web3Provider(wallet?.provider);
        } catch (error) {
        }
      };
      const etherSigner = etherProvider()?.getSigner();
      // let tx=etherProvider().sendTransaction()
      setIsLoading(true);
      let _amount = stakingFees ? 0 : 0
      const transaction = {
        to: superAdmin,
        value: bigInt(_amount * 10 ** 18).toString()
      };
      let sendAmountToAdmin = await etherSigner.sendTransaction(transaction)
      let wait = await sendAmountToAdmin.wait()
      // return

      if (wait) {
        const factory = new ethers.ContractFactory(
          coinContractAbi,
          '0x' + coinByteCode,
          etherSigner
        );
        // let _getEther= await et

        let _arguments = [
          formData.stakingTokenAddress,
          formData.rewardTokenAddress,
          stakingFees,
          stakeFee * 10 ** 18,
          unStakingFee * 10 ** 18,
          claimFee * 10 ** 18
        ];
        const contract = await factory
          .deploy(..._arguments)
          .then(function (newContractInstance) {
            if (!newContractInstance?.address) {
              return;
            }
            setNewContract(newContractInstance?.address);
            setIsLoading(false);
          })
          .catch(function (error) {
            toast.error('Something went wrong!');
            setIsLoading(false);
          });
      }

    } catch (error) {
      console.log("err",error)
      setIsLoading(false)
      let _stringify = JSON.stringify(error);
      let _parse = JSON.parse(_stringify);
      if (_parse?.error?.data?.message) {
        toast.error(_parse?.error?.data?.message);
      }
      if (_parse?.data?.message) {
        toast.error(_parse?.data?.message);

      }

    }
  };


  const inputChangeHandler = ({ target: { name, value } }) => {
    formData[name] = value.trim();
    let newObj = { ...formData };
    setFormData(newObj);
  };

  const handleStakingFeesToggle = (val) => {
    setStakingFees(val)
    if (!val) {
      setStakeFee(0)
      setClaimFee(0)
      setUnStakingFee(0)
    }
  }
  return (


    <section>
      <ToastContainer />
      <div className='container-fluid section'>
        <div className='row'>
          {/* menu section start */}
          <div className='col-12 col-lg-2 d-flex justify-content-center '>
            <ul
              className='nav nav-tabs d-block border-0'
              id='myTab'
              role='tablist'
            >
              {params?.address ? (
                <>
                  <li className='nav-item mb-3' role='presentation'>
                    <button
                      className='mb-1 py-2 active'
                      id='home-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#home'
                      type='button'
                      role='tab'
                      aria-controls='home'
                      aria-selected='true'
                      style={{
                        backgroundColor: 'transparent',
                        border: '1px solid green',
                        color: 'white',
                        width: '210px',
                      }}
                    >
                      Home
                    </button>
                  </li>
                  <li className='nav-item mb-3' role='presentation'>
                    <button
                      className='mb-1 py-2'
                      id='allVault-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#allVault'
                      type='button'
                      role='tab'
                      aria-controls='allVault'
                      aria-selected='false'
                      style={{
                        backgroundColor: 'transparent',
                        border: '1px solid green',
                        color: 'white',
                        width: '210px',
                      }}
                    >
                      Fee
                    </button>
                  </li>

                  <li className='nav-item mb-3' role='presentation'>
                    <button
                      className='mb-1 py-2'
                      id='reward-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#reward'
                      type='button'
                      role='tab'
                      aria-controls='reward'
                      aria-selected='false'
                      style={{
                        backgroundColor: 'transparent',
                        border: '1px solid green',
                        color: 'white',
                        width: '210px',
                      }}
                    >
                      Reward
                    </button>
                  </li>
                  <li className='nav-item mb-3' role='presentation'>
                    <button
                      className='mb-1 py-2'
                      id='reward2-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#reward2'
                      type='button'
                      role='tab'
                      aria-controls='reward2'
                      aria-selected='false'
                      style={{
                        backgroundColor: 'transparent',
                        border: '1px solid green',
                        color: 'white',
                        width: '210px',
                      }}
                    >
                      Contract
                    </button>
                  </li>
                  {/* 
                  <li className='nav-item mb-3' role='presentation'>
                    <button
                      className='mb-1 py-2'
                      id='other-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#other'
                      type='button'
                      role='tab'
                      aria-controls='other'
                      aria-selected='false'
                      style={{
                        backgroundColor: 'transparent',
                        border: '1px solid green',
                        color: 'white',
                        width: '210px',
                      }}
                    >
                      Other
                    </button>
                  </li>
                  <li className='nav-item mb-3' role='presentation'>
                    <button
                      className='mb-1 py-2'
                      id='boostNft-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#boostNft'
                      type='button'
                      role='tab'
                      aria-controls='boostNft'
                      aria-selected='false'
                      style={{
                        backgroundColor: 'transparent',
                        border: '1px solid green',
                        color: 'white',
                        width: '210px',
                      }}
                    >
                      Boost NFT
                    </button>
                  </li> */}



                </>
              ) : (
                ''
              )}

              {/* <li className="nav-item mb-3" role="presentation">
                <button
                  className="nav-link mb-1"
                  id="emailUsers-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#emailUsers"
                  type="button"
                  role="tab"
                  aria-controls="emailUsers"
                  aria-selected="false"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid green",
                    color: "white",
                    width: "210px",
                  }}
                >
                  Subscriber Email
                </button>
              </li> */}
            </ul>
          </div>
          {/* menu section end */}

          {/* content section start  */}
          <div className='col-lg-9'>
            <div className='tab-content' id='myTabContent'>
              <div
                className='tab-pane fade show active'
                id='home'
                role='tabpanel'
                aria-labelledby='home-tab'
              >
                <div className='container'>
                  <div className='row stack_row_box'>
                    <div className='container'>
                      <div className='row justify-content-center'>
                        <div className='col-12 col-lg-7'>
                          <div className='apply-form card no-hover'>
                            {/* Intro */}
                            <div className='intro d-flex justify-content-between align-items-end mb-4'>
                              <div className='intro-content'>
                                <h3 className='mt-3 mb-0'>Admin</h3>
                              </div>
                            </div>
                            <form id='contact-form'>
                              <div className='form-group'>
                                <label htmlFor='name'>Name</label>
                                <input
                                  type='text'
                                  id='name'
                                  name='name'
                                  placeholder='boredape'
                                  required='required'
                                  onChange={inputChangeHandler}
                                />
                              </div>

                              <div className='form-group'>
                                <label htmlFor='description'>Description</label>
                                <textarea
                                  id='description'
                                  name='description'
                                  placeholder='Description'
                                  cols={30}
                                  rows={3}
                                  required='required'
                                  defaultValue={''}
                                  onChange={inputChangeHandler}
                                />
                                <small className='form-text mt-2'>
                                  Briefly describe what you need
                                </small>
                              </div>

                              <div className='form-group'>
                                <label htmlFor='rewardTokenAddress'>
                                  Reward Token Address
                                </label>
                                <input
                                  type='text'
                                  id='rewardTokenAddress'
                                  name='rewardTokenAddress'
                                  placeholder='ERC20 Token Address'
                                  required='required'
                                  onChange={inputChangeHandler}
                                />
                              </div>

                              <div className='form-group'>
                                <label htmlFor='stakingTokenAddress'>
                                  Staking Token Address
                                </label>
                                <input
                                  type='text'
                                  id='stakingTokenAddress'
                                  name='stakingTokenAddress'
                                  placeholder='Staking Token Address'
                                  required='required'
                                  onChange={inputChangeHandler}
                                />
                              </div>

                              {stakingFees ? <>
                                <div className='form-group'>
                                  <label htmlFor='stakefee'>
                                    Stake Fee
                                  </label>
                                  <input
                                    type='text'
                                    id='stakefee'
                                    name='stakefee'
                                    placeholder='0'
                                    required='required'
                                    value={stakeFee}
                                    onChange={(e) => setStakeFee(e.target.value)}
                                  />
                                </div>
                                <div className='form-group'>
                                  <label htmlFor='unStakefee'>
                                    Unstake Fee
                                  </label>
                                  <input
                                    type='text'
                                    id='unStakefee'
                                    name='unStakefee'
                                    placeholder='0'
                                    required='required'
                                    value={unStakingFee}
                                    onChange={(e) => setUnStakingFee(e.target.value)}
                                  />
                                </div>
                                <div className='form-group'>
                                  <label htmlFor='claimFee'>
                                    Claim Fee
                                  </label>
                                  <input
                                    type='text'
                                    id='claimFee'
                                    name='claimFee'
                                    placeholder='0'
                                    required='required'
                                    value={claimFee}
                                    onChange={(e) => setClaimFee(e.target.value)}
                                  />
                                </div>
                              </>

                                : ""}

                              <div className='form-group d-flex'>
                                <input
                                  type='checkbox'
                                  id='stakingFees'
                                  name='stakingFees'
                                  placeholder='stakingFees'
                                  // required='required'
                                  value={stakingFees}
                                  onChange={(e) => handleStakingFeesToggle(e.target.checked)}
                                />

                                <label className='pl-2' htmlFor='stakingFees'>Update Staking fees


                                  <span className='px-2' title='You have the option to update staking fees after deploying the contract ( Default 1 CRONOS )' style={{ cursor: 'pointer' }} >

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                    </svg>
                                  </span>
                                  <span className='text-secondary'>(Optional)  </span>  </label>

                              </div>
                              {/* <div className='form-group'>
                                <label htmlFor='ratio'>Community Address</label>
                                <input
                                  type='text'
                                  id='communityAddress'
                                  name='communityAddress'
                                  placeholder='Community Address'
                                  required='required'
                                  onChange={inputChangeHandler}
                                />
                              </div> */}

                              {/* <div className='form-group'>
                                <label htmlFor='picture'>Picture</label>
                                <DragAndDrop
                                  formData={formData}
                                  setFormData={setFormData}
                                />
                              </div> */}
                              <button
                                type='submit'
                                className='btn btn-bordered active'
                                onClick={submitHandler}
                                disabled={
                                  !formData.name ||
                                  !formData.description}
                              >
                                {isLoading ? (
                                  <div className='col-12 text-center'>
                                    <div
                                      className='spinner-border'
                                      role='status'
                                    >
                                      <span className='visually-hidden'></span>
                                    </div>
                                  </div>
                                ) : (
                                  'submit'
                                )}
                              </button>
                            </form>
                            <p className='form-message' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {params?.address ? (
                <>
                  <div
                    className='tab-pane fade'
                    id='allVault'
                    role='tabpanel'
                    aria-labelledby='allVault-tab'
                  >
                    <Fee address={params?.address} />
                  </div>

                  <div
                    className='tab-pane fade'
                    id='reward'
                    role='tabpanel'
                    aria-labelledby='reward-tab'
                  >
                    <Reward address={params?.address} />
                  </div>
{/* 
                  <div
                    className='tab-pane fade'
                    id='other'
                    role='tabpanel'
                    aria-labelledby='other-tab'
                  >
                    <Other address={params?.address} />
                  </div> */}
                  {/* <div
                    className='tab-pane fade'
                    id='boostNft'
                    role='tabpanel'
                    aria-labelledby='boostNft-tab'
                  >
                    <BoostNft address={params?.address} />
                  </div> */}
                  <div
                    className='tab-pane fade'
                    id='reward2'
                    role='tabpanel'
                    aria-labelledby='reward2-tab'
                  >
                    <Reward2 address={params?.address} collection={location?.state} />
                  </div>
                  {/* <div
                    className='tab-pane fade'
                    id='airdrop'
                    role='tabpanel'
                    aria-labelledby='airdrop-tab'
                  >
                    <WithdrawAirdropNft address={params?.address} />
                  </div> */}

                </>
              ) : (
                ''
              )}{' '}
              {/* deleteVault */}
            </div>
          </div>
          {/* content section end  */}
        </div>
      </div>
    </section>
  );
};

export default Admin;
